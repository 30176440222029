styleInputs();

/*------------------ Global
 */

function styleInputs() {
	let inputs = document.querySelectorAll('.input');

	inputs.forEach(input => {
		if (input.classList.contains('select')) {
			styleSelect(input);
		}

		if (input.classList.contains('date')) {
			styleDate(input);
		}

		if (input.classList.contains('checkbox')) {
			styleCheckbox(input);
		}
	});

	documentInputListener();
}

/**
 * Document listener to close inputs, if necessary
 */
function documentInputListener() {
	document.addEventListener('click', () => {
		let inputs = document.querySelectorAll('.input');

		inputs.forEach(input => {
			if (input.classList.contains('select')) {
				input.classList.remove('open');
			}
		});
	});
}

/*------------------ Select
 */

/**
 *
 * @param {HTMLElement} select
 */
function styleSelect(select) {
	// Original select element and options
	let originalSelect = select.querySelector('select');
	let originalOptions = originalSelect.querySelectorAll('option');

	// Make original display invisible
	originalSelect.style.display = 'none';

	let selectInnerHTML = `
	<div class="select-list">
	`;

	// Go through all original options
	originalOptions.forEach(originalOption => {
		// Create new option from old, as div
		let optionHTML = `
		<div class="select-option" data-value="${originalOption.value}">
			${originalOption.textContent}
		</div>
		`;

		// Add to list
		selectInnerHTML += optionHTML;
	});

	selectInnerHTML += `</div>`;

	// Concatenating to not remove old select
	select.innerHTML += selectInnerHTML;
	select.addEventListener('click', handleSelect);

	if (originalSelect.hasAttribute('data-default-index')) {
		setSelectOption(select, parseInt(originalSelect.getAttribute('data-default-index')));
	} else {
		setSelectOption(select, 0);
	}
}

/**
 *
 * @param {HTMLElement} select
 * @param {Number} n
 */
function setSelectOption(select, n) {
	// Actual select element
	let originalSelect = select.querySelector('select');

	// Select options
	let options = select.querySelectorAll('.select-option');
	let selectActive = select.querySelector('.select-active');

	options.forEach(option => {
		option.classList.remove('active');
	});

	selectActive.textContent = options[n].textContent;
	originalSelect.value = options[n].getAttribute('data-value');

	originalSelect.dispatchEvent(new Event('change'));
}

/**
 *
 * @param {Event} event
 */
function handleSelect(event) {
	event.stopPropagation();
	let select = this;

	if (select.classList.contains('open')) {
		// Select is already open
		if (event.target.classList.contains('select-option')) {
			let options = select.querySelectorAll('.select-option');
			let option = event.target;

			// Go through all options, check which is clicked
			// For loop instead of ForEach because index is needed
			for (let i = 0; i < options.length; i++) {
				if (options[i].getAttribute('data-value') == option.getAttribute('data-value')) {
					setSelectOption(select, i);
					break;
				}
			}
		}

		select.classList.remove('open');
	} else {
		// Select is still closed

		// Close all selects
		let allSelects = document.querySelectorAll('.select');
		allSelects.forEach(otherSelect => {
			otherSelect.classList.remove('open');
		});

		// Open clicked select
		select.classList.add('open');
	}
}

/*------------------ Date
 */

/**
 *
 * @param {HTMLElement} date
 */
function styleDate(date) {
	// Original date element
	let originalDate = date.querySelector('input[type="date"]');
	// Make original display invisible

	originalDate.classList.add('inactive');

	let options = {
		onChange: datetime => {
			updateDate(date, datetime);
		},
		locale: 'nl',
		minDate: 'today'
	};

	if (originalDate.getAttribute('data-range') == 'true') {
		options.mode = 'range';
	}

	if (originalDate.getAttribute('data-default-today') == 'true') {
		options.defaultDate = 'today';
	}

	let datePicker = flatpickr(date, options);
}

/**
 *
 * @param {HTMLElement} date
 * @param {Date[]} datetimes
 */
function updateDate(date, datetime) {
	// Date as text for users
	let dateString = datetime[0].toLocaleDateString();
	date.querySelector('.date-active').textContent = dateString;

	// Original input date element
	let originalDate = date.querySelector('input[type="date"]');

	let originalDateString =
		datetime[0].getFullYear().toString() +
		'-' +
		(datetime[0].getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		datetime[0].getDate().toString().padStart(2, '0');

	if (datetime.length > 1) {
		let originalEndDate = document.querySelector(originalDate.getAttribute('data-end-input'));

		let dateString1 = datetime[1].toLocaleDateString();
		date.querySelector('.date-active').textContent += ' tot ' + dateString1;

		let originalDateString1 =
			datetime[1].getFullYear().toString() +
			'-' +
			(datetime[1].getMonth() + 1).toString().padStart(2, '0') +
			'-' +
			datetime[1].getDate().toString().padStart(2, '0');

		originalEndDate.value = originalDateString1;
		originalEndDate.dispatchEvent(new Event('change'));
	}

	originalDate.value = originalDateString;
	originalDate.dispatchEvent(new Event('change'));
}

/*------------------ Checkbox
 */

/**
 *
 * @param {HTMLElement} checkbox
 */
function styleCheckbox(checkbox) {
	// Original checkbox element
	let originalCheckbox = checkbox.querySelector('input[type="checkbox"]');
	// Make original display invisible

	originalCheckbox.classList.add('inactive');
	let newCheckbox = checkbox.querySelector('.checkbox-box');

	originalCheckbox.addEventListener('change', () => {
		if (originalCheckbox.checked) {
			newCheckbox.classList.add('active');
		} else {
			newCheckbox.classList.remove('active');
		}
	});
}
